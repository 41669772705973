import { CHANGE_ORAGNISATION } from "redux/constants/Org";

const initState = {
    id: "",
    name: "",
    isOwner: false,
    userType: "",
    code: "",
    icon: "",
    phone: "",
	hasAi:false,
};

const org = (state = initState, action) => {
	switch (action.type) {
		case CHANGE_ORAGNISATION: {
			return {
				...state,
				...action.org,
			}
		}
		default:
			return state;
	}
}

export default org