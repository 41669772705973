import fetch from "auth/FetchInterceptor";
import { useDispatch } from "react-redux";
import { signOut } from "redux/actions/Auth";

const JwtAuthService = {};
var refresh = 0;
//const delay = 3000
const delay = 3600 * 1000 - 700; // 1 houre - 700ms -> 3600 * 1000 - 700;

function refreshToken() {
  return fetch({
    url: "/token/refresh",
    method: "Get",
  });
}

function StopRefresh() {
  clearInterval(refresh);
}

JwtAuthService.refreshToken = function Ref() {
  const dispatch = useDispatch();
  if (refresh === 0) {
    refreshToken().then((resp) => {
      if (resp !== 401) {
        refresh = setInterval(refreshToken, delay);
      } else {
        clearInterval(refresh);
        dispatch(signOut());
      }
    });
  }
};

JwtAuthService.login = function (data) {
  refresh = setInterval(refreshToken, delay);
  //credit = setInterval(getCredit, delayCredit);
  return fetch({
    url: "/login_check",
    method: "post",
    data: data,
  });
};

JwtAuthService.signOut = function () {
  StopRefresh();
  return fetch({
    url: "/user/logout",
    method: "Get",
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: "/user/signup",
    method: "post",
    data: data,
  });
};

export default JwtAuthService;
