import { useSelector } from "react-redux";

const useUserType = (requiredTypes) => {
    const userType = useSelector((state) => state.org.userType);
    var hasRequiredType = false;
        
    if (Array.isArray(requiredTypes)) {
        requiredTypes.forEach(requiredType => {
            if (requiredType.includes(userType)) {
                hasRequiredType = true;
            }
        })
    } else {
        hasRequiredType = userType === requiredTypes;
    }

    return {
        userType: userType,
        hasRequiredType: hasRequiredType,
        exact: userType === requiredTypes,
    };
};

export default useUserType;