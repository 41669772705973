import React, { useEffect, useState } from "react";
import {
    Menu,
    Dropdown,
    Button,
    Avatar,
    Tooltip,
    message
} from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { ChangeOrganisation } from "redux/actions";
import {
    PlusOutlined,
    RollbackOutlined,
    LoginOutlined,
    BankOutlined
} from '@ant-design/icons';
import OrganisationService from "services/OrganisationService";
import { env } from "configs/EnvironmentConfig";
import JoinModel from '../../views/app-views/home/AddOrganisation';
import IntlMessage from "components/util-components/IntlMessage";
export const NavOrganisation = (props) => {
    const { ChangeOrganisation,
        name,
        icon,
        id,
        username,
    } = props;
    const orgId = useSelector(state => state.org.id);

    const [ownedOrganisation, setOwnedOrganisation] = useState([]);
    const [joinedOrganisation, setJoinedOrganisation] = useState([]);
    const dispatch = useDispatch();
    const [showJoinModel, setShowJoinModel] = useState(false)

    const handleClose = () => {
        setShowJoinModel(false);
    };

    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    useEffect(() => {
        OrganisationService.GetJoinedOrganisations().then((response) => {
            setJoinedOrganisation(response.data);
        }).catch((err) => {
            console.log(err.message);
        });
        OrganisationService.GetOwnedOrganisation().then((response) => {
            setOwnedOrganisation(response.data);
        }).catch((err) => {
            console.log(err.message);
        });
    }, []);

    const userTypeInOrganisation = (organisation) => {
        return organisation.members.find((member) => member.username === username).type;
    }

    const leaveOrganisation = (orgId) => {
        OrganisationService.leave(orgId).then((response) => {
            if (response.code === 200) {
                setTimeout(() => {
                    dispatch(ChangeOrganisation({
                        id: "",
                        name: "",
                        isOwner: false,
                        userType: "",
                        code: "",
                        icon: "",
                        phone: "",
                        hasAi: false
                    }));
                    window.location.assign("/app/home");
                }, 100);
            } else {
                message.error(response.error.details);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const organisationMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header d-flex justify-content-between">
                <div className="ml-2" onClick={() => setShowJoinModel(true)} style={{ cursor: 'pointer' }}>
                    <Avatar size={30}><PlusOutlined /></Avatar>
                    <span className="ml-2">{setLocale(true, "organisation.join")}</span>
                </div>
                <div className="ml-2" onClick={(e) => {
                    dispatch(ChangeOrganisation({
                        id: "",
                        name: "",
                        isOwner: false,
                        userType: "",
                        code: "",
                        icon: "",
                        phone: "",
                        hasAi: false
                    }));
                    setTimeout(() => {
                        window.location.assign("/app/home");
                    }, 100);
                }}
                    style={{ cursor: 'pointer' }}
                >
                    <Tooltip title={setLocale(true, "profile.back")}>
                        <Avatar size={30} style={{ backgroundColor: 'white' }}>
                            <RollbackOutlined style={{ color: 'black' }} />
                        </Avatar>

                    </Tooltip>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {ownedOrganisation.length > 0 && ownedOrganisation.map((organisation) => (
                        <Menu.Item
                            key={organisation.id}
                            onClick={(e) => {
                                dispatch(ChangeOrganisation({
                                    id: organisation.id,
                                    name: organisation.name,
                                    isOwner: true,
                                    userType: "owner",
                                    code: organisation.code,
                                    icon: organisation.icon,
                                    phone: organisation.phone,
                                    hasAi: organisation.hasAi
                                }));
                                setTimeout(() => {
                                    window.location.assign("/app/home");
                                }, 100);
                            }}
                            className="d-flex justify-content-between"
                        >
                            <div>
                                {organisation.icon ?
                                    <Avatar src={env.IMG_SRC + organisation.icon}></Avatar>
                                    :
                                    <Avatar>{organisation.name}</Avatar>
                                }
                                <span className="ml-2">{organisation.name}</span>
                            </div>
                        </Menu.Item>
                    ))}
                    {joinedOrganisation.length > 0 && joinedOrganisation.map((organisation) => (
                        <Menu.Item
                            key={organisation.id}
                            onClick={(e) => {
                                dispatch(ChangeOrganisation({
                                    id: organisation.id,
                                    name: organisation.name,
                                    isOwner: false,
                                    userType: userTypeInOrganisation(organisation),
                                    code: organisation.code,
                                    icon: organisation.icon,
                                    phone: organisation.phone,
                                    hasAi: organisation.hasAi
                                }));
                                setTimeout(() => {
                                    window.location.assign("/app/home");
                                }, 100);
                            }}
                            className="d-flex justify-content-between"
                        >
                            <div>
                                {organisation.icon ?
                                    <Avatar src={env.IMG_SRC + organisation.icon}></Avatar>
                                    :
                                    <Avatar>{organisation.name}</Avatar>
                                }
                                <span className="ml-2">{organisation.name}</span>
                            </div>
                        </Menu.Item>
                    ))}
                </Menu>
            </div>

        </div>
    );

    return (
        <>
            {ownedOrganisation.length === 0 && joinedOrganisation.length === 0 ?
                <Button className="mt-3" onClick={() => setShowJoinModel(true)}>
                    <PlusOutlined />
                    <span>{setLocale(true, "new")}</span>
                </Button>
                :
                <Dropdown
                    placement="bottomRight"
                    overlay={organisationMenu}
                    trigger={["click"]}
                >
                    <Menu className="d-flex align-item-center" mode="horizontal">
                        <Menu.Item>
                            <Tooltip title={setLocale(true, "profile.organisation")}>
                                {id ?
                                    icon ?
                                        <Avatar src={env.IMG_SRC + icon}></Avatar>
                                        :
                                        <Avatar>{name}</Avatar>
                                    :
                                    <Avatar size={50} className="mb-1 bg-white" icon={<BankOutlined style={{ color: 'black' }} />} />
                                }
                            </Tooltip>
                        </Menu.Item>
                    </Menu>
                </Dropdown>
            }
            <JoinModel visible={showJoinModel} close={handleClose} />

        </>
    );
};

const mapStateToProps = ({ org, auth }) => {
    const { id, name, type, isOwner, icon } = org;
    const { username } = auth;
    return { id, name, type, isOwner, icon, username };
};

export default connect(mapStateToProps, { ChangeOrganisation })(NavOrganisation);