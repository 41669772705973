import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, ROOM_PREFIX_PATH, NOT_FOUND_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import { ROLE_CUSTOMER} from "../redux/constants/Auth";
import ErrorOne from "./errors/error-page-1";
import JwtAuthService from "services/JwtAuthService";
import RoomLayout from "layouts/room-layout";
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from "auth/gard/PrivateRoute";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  JwtAuthService.refreshToken();

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <GoogleOAuthProvider clientId="631840218309-m27i9keq8ucl8rib7j1bpamokerah91g.apps.googleusercontent.com">
          <Switch>
            <Route exact path="/">
              <Redirect to={AUTH_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>
            <PrivateRoute path={APP_PREFIX_PATH} AuthNeeded={ROLE_CUSTOMER}>
              <AppLayout direction={direction} location={location} />
            </PrivateRoute>
            <Route path={ROOM_PREFIX_PATH} >
              <RoomLayout direction={direction} location={location} />
            </Route>
            <Route path={NOT_FOUND_PATH}>
              <ErrorOne />
            </Route>
            <Redirect to={APP_PREFIX_PATH} />
          </Switch>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};


export default withRouter(connect(mapStateToProps)(Views));
