import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
                <Route path={`${APP_PREFIX_PATH}/meetings`} component={lazy(() => import(`./meetings`))} />
                <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
                <Route path={`${APP_PREFIX_PATH}/team`} component={lazy(() => import(`./team`))} />
                <Route path={`${APP_PREFIX_PATH}/setting`} component={lazy(() => import(`./settings`))} />
                <Route path={`${APP_PREFIX_PATH}/plan`} component={lazy(() => import(`./plan`))} />
                <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./administration`))} />
                <Route path={`${APP_PREFIX_PATH}/tutorial`} component={lazy(() => import(`./tutorial`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);
